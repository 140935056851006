<template>
	<div id="warper">
		<el-card class="center">
			<div class="title">兰溪市部分国有企业公开招聘报名信息表</div>
			<el-form class="fill_data">
				<table class="eltable" border="1" cellpadding="2" cellspacing="0" align="center">
					<tr>
						<td width="94">姓 名</td>
						<td width="150">
							{{form.name}}
						</td>
						<td width="75">性 别</td>
						<td width="75">
							{{form.sex}}
						</td>
						<td width="100">政治面貌</td>
						<td width="100">
							{{form.politicalName}}
						</td>
						<td width="100">出生年月</td>
						<td width="100">
							{{form.birthday}}
						</td>
						<td width="140" rowspan="5">
							<img :src="defimgurl+form.avatar" fit="contain" width="100" height="120"></img>
						</td>
					</tr>
					<tr>
						<td>身份证号</td>
						<td colspan="3">
							{{form.idcard}}
						</td>
						<td>婚姻状况</td>
						<td>
							{{form.marry}}
						</td>
						<td>户籍地</td>
						<td>
							{{form.houseHoldName}}
						</td>
					</tr>
					<tr>
						<td>毕业院校</td>
						<td colspan="3">
							{{form.school}}
						</td>
						<td>所学专业</td>
						<td colspan="3">
							{{form.professional}}
						</td>
					</tr>
					<tr>
						<td>毕业时间</td>
						<td colspan="2">
							{{form.educationDate}}
						</td>
						<td>学 历</td>
						<td>
							{{form.education}}
						</td>
						<td>学 位</td>
						<td colspan="2">
							{{form.degree}}
						</td>
					</tr>
					<tr>
						<td>报名单位</td>
						<td colspan="3">
							<!-- {{form.examCompanyId}} -->
							{{info.examCompanyId}}
						</td>
						<td>报名岗位 </td>
						<td colspan="3">
							<!-- {{form.examCompanyItemName}} -->
							{{info.examCompanyItemName}}
						</td>
					</tr>
					<tr>
						<td rowspan="4">学习经历<br>（从高中以上填起）</td>
						<td colspan="3">起止时间</td>
						<td colspan="4">所在学校、专业</td>
						<td>职务或表现</td>
					</tr>
					<tr v-for="item in form.learnings">
						<td>
							{{item.startTime}}
						</td>
						<td colspan="2">
							{{item.endTime}}
						</td>
						<td colspan="4">
							{{item.des}}
						</td>
						<td>
							{{item.mome}}
						</td>
					</tr>
					<tr>
						<td rowspan="6">工作简历 （正式就业）</td>
						<td colspan="3"> 起止时间 </td>
						<td colspan="4"> 工作单位 </td>
						<td> 职务或表现 </td>
					</tr>
					<tr v-for="item in form.works">
						<td>
							{{item.startTime}}
						</td>
						<td colspan="2">
							{{item.endTime}}
						</td>
						<td colspan="4">
							{{item.des}}
						</td>
						<td>
							{{item.mome}}
						</td>
					</tr>
					<tr>
						<td>工作业绩或受奖励、处分情况 </td>
						<td colspan="8">
							{{form.jobs_reward}}
						</td>
					</tr>
					<tr>
						<td>健康状况</td>
						<td colspan="3">良好</td>
						<td>特长</td>
						<td colspan="4">
							{{form.specialty}}
						</td>
					</tr>
					<tr>
						<td rowspan="4">专业职业资格证书情况</td>
						<td>专业</td>
						<td colspan="2">等级</td>
						<td colspan="4">资格证书全称</td>
						<td>批准时间</td>
					</tr>
					<tr v-for="item in form.professional_book">
						<td>
							{{item.professional}}
						</td>
						<td colspan="2">
							{{item.level}}
						</td>
						<td colspan="4">
							{{item.name}}
						</td>
						<td>
							{{item.appTime}}
						</td>
					</tr>
					<tr>
						<td rowspan="5">家庭主<br>要成员<br>（父母、配偶及子女） </td>
						<td>关 系</td>
						<td colspan="2">姓 名</td>
						<td colspan="4">在何地何单位工作（学习）</td>
						<!-- 之前字段为详细地址，数据库方面考虑，homeaddress当作手机号码字段使用 -->
						<td>手机号码</td>
					</tr>
					<tr v-for="item in form.home_man">
						<td>
							{{item.between}}
						</td>
						<td colspan="2">
							{{item.name}}
						</td>
						<td colspan="4">
							{{item.workaddress}}
						</td>
						<td>
							{{item.homeaddress}}
						</td>
					</tr>
					<tr>
						<td>现居住地址 </td>
						<td colspan="3">
							{{form.homeAddress}}
						</td>
						<td>通信地址</td>
						<td colspan="2">
							{{form.emailAddress}}
						</td>
						<td>邮 编</td>
						<td>
							{{form.zipCode}}
						</td>
					</tr>
					<tr>
						<td>手机号码</td>
						<td colspan="3">
							{{form.phone}}
						</td>
						<td>电子信箱</td>
						<td colspan="3">
							{{form.email}}
						</td>
						<td> </td>
					</tr>
				</table>
			</el-form>
			<div style="display: flex;width: 100%;justify-content: center;padding-top: 154px;padding-bottom: 45px;">
				<div class="baocun" @click="down">下载</div>
				<div class="baocun" @click="back">关闭</div>
				<div v-if="this.status == 2 || this.status == 99" class="baocun" @click="gozkInfoPage(form.examId)">重新报名</div>
				<div v-if="this.status == 0" class="cancel" @click="cancelSign(form)">取消报名</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	import {
		isnull
	} from "@/assets/js/index";
	import {
		ExamSignUpAudit,
		ExamSignUpInfo,
		ExamCompanyItemSelectList,
		ExamSignUpDown,
		ExamCancelSign
	} from "@/api/user";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	import global from "@/assets/js/globalconfig";
	import iconfont from "@/assets/iconfont";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				baseurl: global.baseurl,
				JobsDialog: false,
				userinfo: {},
				id: 0,
				status: 0,
				ExamCompanyItem: "",
				form: {},
				headers: {
					token: localStorage.getItem("token"),
				},
				learnings_info: {
					startTime: '',
					endTime: '',
					des: '',
					mome: ''
				},
				works_info: {
					startTime: '',
					endTime: '',
					des: '',
					mome: ''
				},
				professional_book: {
					professional: '',
					level: '',
					name: '',
					appTime: ''
				},
				home_man: {
					between: '',
					name: '',
					workaddress: '',
					homeaddress: ''
				},
			}
		},
		mounted() {},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			}

			this.id = parseInt(this.$route.query.id);
			this.status = parseInt(this.$route.query.status);
			console.log(this.status);
			this.ExamSignUpAudit(this.id)
		},
		methods: {
			cancelSign(item) {
				var _this = this;
				_this.$confirm("确定取消报名吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
				.then(() => {
					ExamCancelSign({
						id: item.id, // 当前报考记录id
						cid: item.examId, // 考试id
					}).then((res) => {
						if (res.success) {
							this.$message({
								message: '已取消报名', //提示的信息
								type: 'success',
								duration: 2000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
					} else {
						this.$message.error(res.msg);
					}
				})
					console.log(id)
				})
				.catch(() => {});
			},
			gozkInfoPage(id) {
				this.$router.push("zhaokaoInfo?id=" + id)
			},
			isArray(v) {
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				return v
			},
			ExamCompanyItemSelectList(e) {
				ExamCompanyItemSelectList({
					KW: this.examId,
					id: e
				}).then((res) => {
					if (res.success) {
						this.sxdata[4].list = res.data
					}
				});
			},
			handleSelectionChange() {},
			//获取上次报名信息
			ExamSignUpAudit(id) {
				var _this = this;
				var par = {
					id: id
				};
				ExamSignUpAudit(par).then((res) => {
					if (res.success) {
						_this.info = res.data;
						// 本地存储上次报名岗位信息
						localStorage.setItem("examCompanyInfo_" + this.userinfo.peR_ID, JSON.stringify(_this.info));
						this.ExamSignUpInfo(res.data.signUpId)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			ExamSignUpInfo(id) {
				var _this = this;
				var par = {
					id: id
				};
				ExamSignUpInfo(par).then((res) => {
					console.log(res.data)
					if (res.success) {
						if (res.data != null) {
							_this.form = res.data;
							// 将上次报名信息保存到localstorage
							console.log(this.userinfo.peR_ID);
							localStorage.setItem("zhaokaobm_" + this.userinfo.peR_ID, JSON.stringify(_this.form));
							// if (isnull(localStorage.getItem("zhaokaobm"))) {
							// 	localStorage.setItem("zhaokaobm", JSON.stringify(_this.form));
							// }
							_this.form.sex = _this.form.sex == "0" ? "男" : "女";
							_this.form.marry = _this.form.marry == "1" ? "未婚" : _this.form.marry == "2" ? "已婚" :
								_this.form.marry == "3" ? "离异" :
								"丧偶";
							var hh = _this.form.houseHold.split(',');
							if (hh.length > 2)
								_this.houseHold = [hh[0], hh[1], hh[2]];

							_this.form.learnings = _this.isArray(_this.form.learnings);
							for (let l = _this.form.learnings.length; l < 3; l++) {
								_this.form.learnings.push({
									..._this.learnings_info
								});
							}
							_this.form.works = _this.isArray(_this.form.works);
							for (let l = _this.form.works.length; l < 5; l++) {
								_this.form.works.push({
									..._this.works_info
								});
							}
							_this.form.professional_book = _this.isArray(_this.form.professional_book);
							for (let l = _this.form.professional_book.length; l < 3; l++) {
								_this.form.professional_book.push({
									..._this.professional_book_info
								});
							}
							_this.form.home_man = _this.isArray(_this.form.home_man); //4
							for (let l = _this.form.home_man.length; l < 4; l++) {
								_this.form.home_man.push({
									..._this.home_man_info
								});
							}
							if (_this.form.attach != '')
								_this.form.attach = _this.isArray(_this.form.attach);
							else
								_this.form.attach = []
						} else {
							_this.form.name = _this.userInfo.peR_NAME;
							_this.form.sex = _this.userInfo.peR_GENDER;
							_this.form.phone = _this.userInfo.peR_PHONE;
							_this.form.birthday = _this.userInfo.peR_BIRTHDAY;
							_this.form.homeAddress = _this.userInfo.peR_ADDRESS;
							_this.form.avatar = _this.userInfo.peR_IMG_PATH;
							_this.form.education = _this.userInfo.peR_HEDU_NAME;
							if (_this.form.sex == null) _this.form.sex = 0;
						}
					}
				})
			},
			back() {
				close();
			},
			down() {
				var _this = this;
				var par = {
					id: _this.id,
					cid: _this.form.id,
				};
				ExamSignUpDown(par).then((res) => {
					console.log(res)
					if (res.success)
						// window.location = defimgurl + res.data.src
						window.location = 'https://www.lxrcsc.net:8086/' + res.data.src
						// window.location = 'https://localhost:44368/' + res.data.src
				})
			},
			Jobsbind(e) {
				this.ExamCompanyItem = e.examCompanyId + "-" + e.name
				this.form.examCompanyId = e.examCompanyId
				this.form.examCompanyItemId = e.id
				this.JobsDialog = false;
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.center {
		width: 1200px;
		margin: 29px auto;


		.title {
			color: #101010;
			font-size: 36px;
			text-align: center;
			font-family: SourceHanSansSC-regular;
			margin-bottom: 15px;
		}
	}

	.el-input__inner {
		padding: 0 2px;
	}

	.eltable {
		text-align: center;
		border-collapse: collapse;

		td {
			height: 30px;
		}
	}

	.baocun {
		width: 200px;
		height: 36px;
		line-height: 36px;
		cursor: pointer;
		background-color: #fff;
		color: #0089FF;
		font-size: 14px;
		text-align: center;
		font-family: Arial;
		border: 1px solid #0089FF;
		margin-right: 75px;
	}

	.cancel {
		width: 200px;
		height: 36px;
		line-height: 36px;
		cursor: pointer;
		background-color: #fff;
		color: #ff0000;
		font-size: 14px;
		text-align: center;
		font-family: Arial;
		border: 1px solid hsl(0, 100%, 50%);
		margin-right: 75px;
	}

	.nextStep {
		width: 200px;
		height: 36px;
		line-height: 36px;
		cursor: pointer;
		background-color: #0089FF;
		color: #fff;
		font-size: 14px;
		text-align: center;
		font-family: Microsoft Yahei;

	}
</style>